.projectSection {
    margin-top: 1%;
    margin-bottom: 15%;
}

.projectTitle {
    font-size: 25px;
}

.projectContent {
    font-size: 25px;
    margin-bottom: 3%;
}

.projectIMG img {
    width: 100%;
}

.projectIMG {
    float: right;
}

.imageTech {
    margin-top: 5%;
}

.repoLink img,
.techBadge img {
    margin: auto;
    vertical-align: middle;
    display: inline-block;
}

a,
a:visited {
    color: black;
}

a:hover {
    color: #818181;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .projectTitle {
        font-size: 20px;
    }
    .projectContent {
        font-size: 18px;
    }
    .projectIMG {
        float: left;
        width: 100%;
        margin-bottom: 3%;
    }
    .demoLinks,
    .techBadge {
        margin-bottom: 2%;
    }
    .techIMG,
    .linkIMG {
        width: 8%;
        margin-right: 2% !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 800px) {
    .projectIMG {
        float: left;
        width: 100%;
        margin-bottom: 3%;
    }
    .demoLinks,
    .techBadge {
        margin-bottom: 1%;
    }
    .techIMG,
    .linkIMG {
        width: 7%;
        margin-right: 1% !important;
    }
}

@media only screen and (min-width: 801px) and (max-width: 1380px) {
    .projectIMG {
        float: right;
        width: 40%;
        margin-bottom: 3%;
    }
    .demoLinks,
    .techBadge {
        margin-bottom: 1%;
    }
    .techIMG,
    .linkIMG {
        width: 5%;
        margin-right: 1% !important;
    }
}

@media only screen and (min-width: 1381px) and (max-width: 2050px) {
    .projectIMG {
        float: right;
        width: 45%;
        margin-bottom: 3%;
        margin-left: 2%
    }
    .demoLinks,
    .techBadge {
        margin-bottom: 1%;
    }
    .techIMG,
    .linkIMG {
        width: 4%;
        margin-right: 1% !important;
    }
}

@media only screen and (min-width: 2051px) {
    .projectTitle {
        padding-top: 4%;
    }
    .projectIMG {
        float: right;
        width: 50%;
        margin-bottom: 3%;
        margin-left: 2%
    }
    .demoLinks,
    .techBadge {
        margin-bottom: 1%;
    }
    .techIMG,
    .linkIMG {
        width: 3%;
        margin-right: 1% !important;
    }
}