body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.navMenu {
    width: 20%;
    height: 100%;
    overflow: hidden;
}

#selHomeNav,
#selAboutNav,
#selProjectsNav,
#selContactNav {
    font-weight: bolder;
}

#navMenuHome, #navMenuAbout, #navMenuProjects, #navMenuContact {
    background-color: #e8e8e8;

}

#navMenuHome {
    border: 1px solid #c2c2c2;
    color: black;
}

#navMenuAbout {
    border: 1px solid #9e0012;
    color: #9e0012;
}

#navMenuProjects {
    border: 1px solid #2d8e39;
    color: #2d8e39;
}

#navMenuContact {
    border: 1px solid #00329e;
    color: #00329e;
}

.navSubProjects a, .navSubProjects a:visited {
    color: #2d8e39;
}

.navMenu {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    overflow: scroll;
    z-index: 10;
}

.navMenu p {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    display: block;
}

.navMenu p:hover {
    font-weight: bolder;
    color: slategray;
}

.navMenuSelect {
    font-size: 30px;
}

.navSubProjects {
    font-size: 15px;
    margin-left: 10%;

}

.menuItems {
    margin-top: 30%;
}

.navClose {
    position: absolute;
    top: 2%;
    right: 5%;
    font-size: 25px;
    margin-left: 50px;
}

@media only screen and (min-width: 0px) and (max-width: 439px) {
    .navMenu {
        width: 60%;
        overflow: hidden;
    }
    .navMenuSelect {
        font-size: 20px;
    }
    .navClose {
        position: absolute;
        top: 1.5%;
        right: 8%;
        font-size: 15px;
        margin-left: 50px;
    }
}


@media only screen and (min-width: 440px) and (max-width: 809px) {
    .navMenuSelect {
        font-size: 20px;
    }
    .navClose {
        position: absolute;
        top: 1.5%;
        right: 8%;
        font-size: 15px;
        margin-left: 50px;
    }
    .navMenu {
        width: 30%;
        overflow: hidden;
    }
}

@media only screen and (min-width: 810px) {
    .navMenu {
        width: 30%;
        overflow: hidden;
    }
}

@media only screen and (min-width: 1024px) {
    .navMenu {
        width: 25%;
        overflow: hidden;
    }
}

@media only screen and (min-width: 1440px) {
     .navMenu {
        width: 20%;
        overflow: hidden;
    }
}

@media only screen and (min-width: 2000px) {
    .navMenu {
        width: 15%;
        overflow: hidden;
    }
}

.hiThere {
    font-size: 50px;
}

.welcomeSection {
    margin-bottom: 10%;
}

.homeContent {
    font-size: 25px;
    margin-bottom: 5%;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .hiThere {
        font-size: 35px;
    }
    .homeContent {
        font-size: 18px;
        margin-bottom: 5%;
    }
}
.aboutContent {
    font-size: 25px;
    margin-bottom: 5%;
}

ul.contentList {
    -webkit-columns: 4;
            columns: 4;
}

li {
    list-style-type: none;
    margin-bottom: 3px;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .aboutContent {
        font-size: 18px;
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 550px) {
    ul.contentList {
        -webkit-columns: 1;
                columns: 1;
    }
    li {
        margin-left: 25%;
    }
}

@media only screen and (min-width: 551px) and (max-width: 800px) {
    ul.contentList {
        -webkit-columns: 2;
                columns: 2;
    }
    li {
        margin-left: 15%;
    }
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
    ul.contentList {
        -webkit-columns: 3;
                columns: 3;
    }
    li {
        margin-left: 10%;
    }
}
.contactContent {
    font-size: 25px;
    margin-bottom: 5%;
}

.contactLinks {
    display: inline-block;
    width: 250px;

}

.contactIMG {
    width: 20%;
}

.emailIMG {
    width: 25%;
}

.contactButton img {
    margin: auto;
    vertical-align: middle;
    display: inline-block;
}

.contactButton {
    width: 100%;

    text-align: left;
    background: #e8e8e8;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
    font-size: 25px;
    color: black;
}

.contactButton:hover {
    color: #818181;
}

@media only screen and (min-width: 0px) and (max-width: 374px) {
    .contactContent {
        font-size: 18px;
        margin-bottom: 5%;
    }
    .contactLinks,
    .contactButton {
        font-size: 18px;
    }
    .emailIMG {
        width: 20%;
    }
}

@media only screen and (min-width: 375px) and (max-width: 480px) {
    .contactContent {
        font-size: 18px;
        margin-bottom: 5%;
    }
    .contactLinks,
    .contactButton {
        font-size: 18px;
    }
    .emailIMG {
        width: 23%;
    }
}
.projectSection {
    margin-top: 1%;
    margin-bottom: 15%;
}

.projectTitle {
    font-size: 25px;
}

.projectContent {
    font-size: 25px;
    margin-bottom: 3%;
}

.projectIMG img {
    width: 100%;
}

.projectIMG {
    float: right;
}

.imageTech {
    margin-top: 5%;
}

.repoLink img,
.techBadge img {
    margin: auto;
    vertical-align: middle;
    display: inline-block;
}

a,
a:visited {
    color: black;
}

a:hover {
    color: #818181;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .projectTitle {
        font-size: 20px;
    }
    .projectContent {
        font-size: 18px;
    }
    .projectIMG {
        float: left;
        width: 100%;
        margin-bottom: 3%;
    }
    .demoLinks,
    .techBadge {
        margin-bottom: 2%;
    }
    .techIMG,
    .linkIMG {
        width: 8%;
        margin-right: 2% !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 800px) {
    .projectIMG {
        float: left;
        width: 100%;
        margin-bottom: 3%;
    }
    .demoLinks,
    .techBadge {
        margin-bottom: 1%;
    }
    .techIMG,
    .linkIMG {
        width: 7%;
        margin-right: 1% !important;
    }
}

@media only screen and (min-width: 801px) and (max-width: 1380px) {
    .projectIMG {
        float: right;
        width: 40%;
        margin-bottom: 3%;
    }
    .demoLinks,
    .techBadge {
        margin-bottom: 1%;
    }
    .techIMG,
    .linkIMG {
        width: 5%;
        margin-right: 1% !important;
    }
}

@media only screen and (min-width: 1381px) and (max-width: 2050px) {
    .projectIMG {
        float: right;
        width: 45%;
        margin-bottom: 3%;
        margin-left: 2%
    }
    .demoLinks,
    .techBadge {
        margin-bottom: 1%;
    }
    .techIMG,
    .linkIMG {
        width: 4%;
        margin-right: 1% !important;
    }
}

@media only screen and (min-width: 2051px) {
    .projectTitle {
        padding-top: 4%;
    }
    .projectIMG {
        float: right;
        width: 50%;
        margin-bottom: 3%;
        margin-left: 2%
    }
    .demoLinks,
    .techBadge {
        margin-bottom: 1%;
    }
    .techIMG,
    .linkIMG {
        width: 3%;
        margin-right: 1% !important;
    }
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Alegreya Sans SC', sans-serif;
    font-size: 20px;
    padding: 25px;
    margin: 0;
    overflow: auto;
    background-color: #e8e8e8;
}

.displayDiv {
    margin-top: 1%;
}

.displayDiv,
footer {
    margin-left: 10%;
    margin-right: 10%;
}

footer {
    font-size: 14px;
    margin-top: 5%;
    margin-bottom: 1%;
    text-align: center;
}

footer a,
footer a:visited {
    color: #666666;
}

footer a:hover {
    color: #111;
}

.closeTag {
    font-size: 70px;
    margin-bottom: 10%;
}

.navIcon {
    font-size: 25px;
    width: 15%;
    position: fixed;
    z-index: 1;
    margin-top: 0%;
}

.upIcon {
    font-size: 25px;
    bottom: 0;
    width: 25%;
    position: fixed;
    z-index: 1;
}

.upIcon::after {
    content: ' TOP';
}

#colorIconBlk {
    color: black;
}

#colorIconRd {
    color: #9e0012;
}

#colorIconGrn {
    color: #2d8e39;
}

#colorIconBlu {
    color: #00329e;
}

.sectionTitle {
    font-size: 30px;
    margin-bottom: 5%;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .navIcon,
    .upIcon {
        margin-left: -3%;
    }
    footer {
        margin-top: 55%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 850px) {
    .navIcon,
    .upIcon {
        margin-left: 1%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 809px) {
    .navIcon::after,
    .upIcon::after {
        content: '';
    }
    .sectionTitle {
        font-size: 20px;
        margin-bottom: 5%;
    }
    .closeTag {
        font-size: 40px;
        text-align: center;
    }
    footer {
        font-size: 10px;
        margin-left: 1%;
        margin-bottom: 1%;
    }
    .displayDiv,
    footer {
        margin-left: 10%;
    }
}

@media only screen and (min-width: 810px) {
    .displayDiv,
    footer {
        margin-left: 35%;
    }
}

@media only screen and (min-width: 1024px) {
    .displayDiv,
    footer {
        margin-left: 30%;
    }
}

@media only screen and (min-width: 1440px) {
    .displayDiv,
    footer {
        margin-left: 25%;
    }
}

@media only screen and (min-width: 2000px) {
    .displayDiv,
    footer {
        margin-left: 20%;
    }
}
