.hiThere {
    font-size: 50px;
}

.welcomeSection {
    margin-bottom: 10%;
}

.homeContent {
    font-size: 25px;
    margin-bottom: 5%;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .hiThere {
        font-size: 35px;
    }
    .homeContent {
        font-size: 18px;
        margin-bottom: 5%;
    }
}