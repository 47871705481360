.contactContent {
    font-size: 25px;
    margin-bottom: 5%;
}

.contactLinks {
    display: inline-block;
    width: 250px;

}

.contactIMG {
    width: 20%;
}

.emailIMG {
    width: 25%;
}

.contactButton img {
    margin: auto;
    vertical-align: middle;
    display: inline-block;
}

.contactButton {
    width: 100%;

    text-align: left;
    background: #e8e8e8;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
    font-size: 25px;
    color: black;
}

.contactButton:hover {
    color: #818181;
}

@media only screen and (min-width: 0px) and (max-width: 374px) {
    .contactContent {
        font-size: 18px;
        margin-bottom: 5%;
    }
    .contactLinks,
    .contactButton {
        font-size: 18px;
    }
    .emailIMG {
        width: 20%;
    }
}

@media only screen and (min-width: 375px) and (max-width: 480px) {
    .contactContent {
        font-size: 18px;
        margin-bottom: 5%;
    }
    .contactLinks,
    .contactButton {
        font-size: 18px;
    }
    .emailIMG {
        width: 23%;
    }
}