* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Alegreya Sans SC', sans-serif;
    font-size: 20px;
    padding: 25px;
    margin: 0;
    overflow: auto;
    background-color: #e8e8e8;
}

.displayDiv {
    margin-top: 1%;
}

.displayDiv,
footer {
    margin-left: 10%;
    margin-right: 10%;
}

footer {
    font-size: 14px;
    margin-top: 5%;
    margin-bottom: 1%;
    text-align: center;
}

footer a,
footer a:visited {
    color: #666666;
}

footer a:hover {
    color: #111;
}

.closeTag {
    font-size: 70px;
    margin-bottom: 10%;
}

.navIcon {
    font-size: 25px;
    width: 15%;
    position: fixed;
    z-index: 1;
    margin-top: 0%;
}

.upIcon {
    font-size: 25px;
    bottom: 0;
    width: 25%;
    position: fixed;
    z-index: 1;
}

.upIcon::after {
    content: ' TOP';
}

#colorIconBlk {
    color: black;
}

#colorIconRd {
    color: #9e0012;
}

#colorIconGrn {
    color: #2d8e39;
}

#colorIconBlu {
    color: #00329e;
}

.sectionTitle {
    font-size: 30px;
    margin-bottom: 5%;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .navIcon,
    .upIcon {
        margin-left: -3%;
    }
    footer {
        margin-top: 55%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 850px) {
    .navIcon,
    .upIcon {
        margin-left: 1%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 809px) {
    .navIcon::after,
    .upIcon::after {
        content: '';
    }
    .sectionTitle {
        font-size: 20px;
        margin-bottom: 5%;
    }
    .closeTag {
        font-size: 40px;
        text-align: center;
    }
    footer {
        font-size: 10px;
        margin-left: 1%;
        margin-bottom: 1%;
    }
    .displayDiv,
    footer {
        margin-left: 10%;
    }
}

@media only screen and (min-width: 810px) {
    .displayDiv,
    footer {
        margin-left: 35%;
    }
}

@media only screen and (min-width: 1024px) {
    .displayDiv,
    footer {
        margin-left: 30%;
    }
}

@media only screen and (min-width: 1440px) {
    .displayDiv,
    footer {
        margin-left: 25%;
    }
}

@media only screen and (min-width: 2000px) {
    .displayDiv,
    footer {
        margin-left: 20%;
    }
}