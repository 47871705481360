.aboutContent {
    font-size: 25px;
    margin-bottom: 5%;
}

ul.contentList {
    columns: 4;
}

li {
    list-style-type: none;
    margin-bottom: 3px;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .aboutContent {
        font-size: 18px;
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 550px) {
    ul.contentList {
        columns: 1;
    }
    li {
        margin-left: 25%;
    }
}

@media only screen and (min-width: 551px) and (max-width: 800px) {
    ul.contentList {
        columns: 2;
    }
    li {
        margin-left: 15%;
    }
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
    ul.contentList {
        columns: 3;
    }
    li {
        margin-left: 10%;
    }
}