
.navMenu {
    width: 20%;
    height: 100%;
    overflow: hidden;
}

#selHomeNav,
#selAboutNav,
#selProjectsNav,
#selContactNav {
    font-weight: bolder;
}

#navMenuHome, #navMenuAbout, #navMenuProjects, #navMenuContact {
    background-color: #e8e8e8;

}

#navMenuHome {
    border: 1px solid #c2c2c2;
    color: black;
}

#navMenuAbout {
    border: 1px solid #9e0012;
    color: #9e0012;
}

#navMenuProjects {
    border: 1px solid #2d8e39;
    color: #2d8e39;
}

#navMenuContact {
    border: 1px solid #00329e;
    color: #00329e;
}

.navSubProjects a, .navSubProjects a:visited {
    color: #2d8e39;
}

.navMenu {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.5s;
    overflow: scroll;
    z-index: 10;
}

.navMenu p {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    display: block;
}

.navMenu p:hover {
    font-weight: bolder;
    color: slategray;
}

.navMenuSelect {
    font-size: 30px;
}

.navSubProjects {
    font-size: 15px;
    margin-left: 10%;

}

.menuItems {
    margin-top: 30%;
}

.navClose {
    position: absolute;
    top: 2%;
    right: 5%;
    font-size: 25px;
    margin-left: 50px;
}

@media only screen and (min-width: 0px) and (max-width: 439px) {
    .navMenu {
        width: 60%;
        overflow: hidden;
    }
    .navMenuSelect {
        font-size: 20px;
    }
    .navClose {
        position: absolute;
        top: 1.5%;
        right: 8%;
        font-size: 15px;
        margin-left: 50px;
    }
}


@media only screen and (min-width: 440px) and (max-width: 809px) {
    .navMenuSelect {
        font-size: 20px;
    }
    .navClose {
        position: absolute;
        top: 1.5%;
        right: 8%;
        font-size: 15px;
        margin-left: 50px;
    }
    .navMenu {
        width: 30%;
        overflow: hidden;
    }
}

@media only screen and (min-width: 810px) {
    .navMenu {
        width: 30%;
        overflow: hidden;
    }
}

@media only screen and (min-width: 1024px) {
    .navMenu {
        width: 25%;
        overflow: hidden;
    }
}

@media only screen and (min-width: 1440px) {
     .navMenu {
        width: 20%;
        overflow: hidden;
    }
}

@media only screen and (min-width: 2000px) {
    .navMenu {
        width: 15%;
        overflow: hidden;
    }
}
